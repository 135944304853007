import React from "react";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	ImportExample,
	Code,
	Header,
} from "../../../../../src/docs";
import { Paragraph } from "../paragraph/paragraph";
import { Ul } from "../list/list";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import { TextContainer } from "../text-container/text-container";
import { Link } from "../../navigation/link/link";
import { SrOnly } from "./sr-only";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Screen reader only (SrOnly)",
	notepad: "https://hackmd.io/oFSZU2fiTZaJguRvOpSf4A",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Screen Reader Only (SrOnly)"
			subTitle="Wrap this invisible component around text that needs to be hidden visually, but kept available to assistive technologies, such as screen readers."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={SrOnly} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					We sometimes leave out text in our UI, because it creates visual clutter and its message
					is communicated through context. That explains why some of our table column headers are
					empty. Despite the empty headers, the content and purpose of the columns remains clear to
					sighted uses.
				</Paragraph>
				<Paragraph>
					This is not the case for non-sighted users and that’s where the SrOnly component comes in.
					This component enables us to hide text, but keep it available to assistive technologies,
					such as screen readers. So, empty table column headers actually do have labels, they’re
					just hidden from view.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The example below may seem empty. However, the Code section shows that it actually
					contains an <Code>SrOnly</Code> component wrapped around a sentence. If you toggle on the
					HTML output, you'll see that this component outputs a <Code>div</Code>.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Status</Header.H3>
				<Paragraph>
					When the status of an element in the UI changes, this is sometimes only communicated
					visually. The <Code>status</Code> property enables us to keep assistive technology users
					informed of such changes. When set to <Code>true</Code>, <Code>role=“status”</Code> and{" "}
					<Code>aria-atomic=“true”</Code> are added to the <Code>div</Code> that the{" "}
					<Code>SrOnly</Code> component outputs. As a result, whenever the content of that{" "}
					<Code>div</Code> changes, its new content is announced to assistive technologies, such as
					a screen reader.
				</Paragraph>
				<InlineMessage variant="warning">
					<Paragraph tone="warning" emphasis="strong">
						This doesn't work:
					</Paragraph>
					<CodeBlock language="tsx">{"isLoading && <SrOnly status>Loading</SrOnly>"}</CodeBlock>
					<Paragraph>
						For the <Code>status</Code> property to work, the SrOnly <Code>div</Code> must be
						present in the DOM before its content changes. This means the condition that triggers
						the content change, must be placed inside the SrOnly component. You can see an example
						of this below.
					</Paragraph>
				</InlineMessage>
				<Example fn={statusExample} />
				<Header.H3>Focus</Header.H3>
				<Paragraph>
					This component can also be used to assist sighted users who navigate using the keyboard.
					SrOnly components are visible when given focus. This functionality can be useful, for
					instance, to create links that make it possible to skip content so that it is not captured
					by assistive technology devices, such as screen readers.
				</Paragraph>
				<Example fn={focusExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={SrOnly}
					initialProps={{
						children: "This text is invisible, but available to assistive technologies.",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use when important information is only communicated visually.",
							"Use to hide text visually, but keep it available to assistive technologies, such as screen readers.",
							<>
								Use the <Code>status</Code> property to announce changes in the UI to assistive
								technologies.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Semantic HTML can be used to communicate the same information to assistive technology users.",
							"The wrapped content is interactive. Interactive content should always be visible.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Preferably, all content should be the same for all users, regardless of their use of
						assistive technology. Use this component sparingly and only when semantic HTML or an{" "}
						<Code>aria-label</Code> don't suffice. Consider consulting an accessibility specialist
						before using it.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"The purpose of any information you include should be to help blind and low vision users successfully navigate the platform.",
							"Don’t repeat textual information that is already present in the UI.",
							"Make sure all information is useful and relevant to the task at hand. Don’t overcompensate out of fear or uncertainty.",
							"Go for clarity over brevity. Don’t skip words, and spell out any abbreviations in full (except for more common ones like SEO).",
							"Be aware of words that are spelled the same but are pronounced differently (like “read” present and “read” past) — make sure the meaning is clear from the context",
							"It might sound obvious now, but remember not to rely on visual cues in your explanations. For example, don’t say “Click the pen icon in the right-hand column”.",
							"Don’t use all caps for emphasis — this may be announced by the screenreader as an acronym.",
							"Formatting (such as bold text, italics, etc.) is redundant — most screenreaders will ignore it.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/** @ignore [visual] */
const defaultExample = () => (
	<SrOnly>This text is invisible, but available to assistive technologies.</SrOnly>
);

const isLoading = true;

/** @ignore [visual] */
const statusExample = () => <SrOnly status>{isLoading && "Loading"}</SrOnly>;

/** @ignore [visual] */
const focusExample = () => (
	<SrOnly>
		<Link href="#properties">Skip to properties section</Link>
	</SrOnly>
);
